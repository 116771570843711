
body,
html {
  height: 100%;
  margin: 0;
  background-color: #ffffff !important;
  font-family: "arial ";
}
#collasible-nav-dropdown > a,
a.dropdown-item {
  font-family: "arial";
  font-size: 1em;
}
.main-content {
  background-color: #fff;
}
.navbar {
  font-family: "Arial";
  background-color: #000 !important;
}

.card-header {
  font-family: "Arial";
  font-size: 1.5em;
}
h2.grid-categorie {
  font-family: "Arial";
  font-size: 1.2em;
  background-color: #000;
  color: #efefef;
  padding: 10px;
  margin-top: 10px;
}
p {
}

h1.detail-project-title {
  font-size: "45px";
}
h2.detail-project-subtitle {
  font-size: "24px";
  color: "#565656";
}
p.detail-project-text {
  font-size: "24px";
}

p.detail-project-materiaux {
  font-size: "14px";
}
p.detail-project-taille {
  font-size: "14px";
}


.did-floating-label-content { 
  position:relative; 
  margin-bottom:20px; 
}
.did-floating-label {
  color:#1e4c82; 
  font-size:13px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:15px;
  top:11px;
  padding:0 5px;
  background:#fff;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}
.did-floating-input, .did-floating-select {
  font-size:12px;
  display:block;
  width:100%;
  height:36px;
  padding: 0 20px;
  background: #fff;
  color: #323840;
  border: 1px solid #3D85D8;
  border-radius: 4px;
  box-sizing: border-box;
  &:focus{
    outline:none;
    ~ .did-floating-label{
      top:-8px;
      font-size:13px;
    }
  }
}

.container-form{
  width: 300px;
  padding: 20px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
}
.did-floating-label-content { 
  position:relative; 
  margin-bottom:20px; 
}
.did-floating-label {
  color:#1e4c82; 
  font-size:13px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:15px;
  top:11px;
  padding:0 5px;
  background:#fff;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
  border-radius: "30px";  
}
.did-floating-input, .did-floating-select {
  font-size:12px;
  display:block;
  width:100%;
  height:36px;
  padding: 0 20px;
  background: #fff;
  color: #323840;
  border: 1px solid #3D85D8;
  border-radius: 4px;
  box-sizing: border-box;
  &:focus{
    outline:none;
    ~ .did-floating-label{
      top:-8px;
      font-size:13px;
    }
  }
}

select.did-floating-select {
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
select.did-floating-select::-ms-expand {
  display: none;
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top:-8px;
  font-size:13px;
}
.did-floating-select:not([value=""]):valid ~ .did-floating-label {
  top:-8px;
  font-size:13px;
}
.did-floating-select[value=""]:focus ~ .did-floating-label {
  top:11px;
  font-size:13px;
}
.did-floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
    background-position: right 15px top 50%;
    background-repeat: no-repeat;
}

.did-error-input{
  .did-floating-input, .did-floating-select {
    border: 2px solid #9d3b3b;
    color:#9d3b3b;
  }
  .did-floating-label{
    font-weight: 600;
    color:#9d3b3b;
  }
  .did-floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
}
}

.input-group {
  display: flex;
  .did-floating-input{
    border-radius:0 4px 4px 0;
    border-left:0;
    padding-left:0;
  }
}
.input-group-append {
  display:flex;
  align-items:center;
/*   margin-left:-1px; */
}
.input-group-text {
    display: flex;
    align-items: center;
    font-weight: 400;
    height:34px;
    color: #323840;
    padding: 0 5px 0 20px;
  font-size:12px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #3D85D8;
  border-radius: 4px 0 0 4px;
  border-right:none;
}

.social-container {
  background: #efefef;
  padding: 25px 50px;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}
